@tailwind base;
@tailwind components;
@tailwind utilities;



.MuiGrid-container::-webkit-scrollbar {
    display: none !important;
}

.terminalResponse {
    color: #c4c3d0;
    font-family: "Fira Code";
    font-size: 12px;
}